@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
    line-height: 16px;
    font-size: 16px;
    box-sizing: border-box;
    height: 0;
}
}

@media screen and (max-width: 1024px){

        html {
        font-size: 12px;
    }

}

@font-face {
    font-family: 'ModernEraMono-Regular';
    src: url('./utils/Fonts/ModernEraMono-Regular.woff') format('woff'),
         url('./utils/Fonts/ModernEraMono-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

input, textarea, select, li, a {
    outline: none;
}

Nav a.active {
right: 0; 
flex: none; 
border-bottom-width: 4px; 
height: 2rem; 
font-style: normal; 
font-weight: 400; 
border-color: #FD5A1E;
letter-spacing: -0.025em; 
color: #fff; 
padding-bottom: .5rem;
 }

a:hover {
text-decoration: none;
color:#fff
}


.PhoneInputCountry{
    border: 1px solid;
    margin-top: 1rem;
    padding: 11px;
    border-radius: 11px;
}
.PhoneInput input {
    width: 76%;
    height: 3.5rem;
    background-color: black;
    border: 1.5px solid #141416;
    border-radius: 6px;
    margin-top: 1rem;
    color: #D7D7D8;
    padding: 1rem;
    outline: none;
}

.PhoneInputInput {
    flex: none !important;
}

@media screen and (min-width: 1024px){

    .PhoneInput input {
        width: 82%;
    }
   
}

@media screen and (max-width: 1024px){

    .rightBox form input {
        display: block;
     }
  
     .rightBox form textarea.message{
        display: block;
    } 
    
    .rightBox form select {
        display: block;
    }
}

#Services {
    /* box-shadow: 0 0 0 10px #b6bdc3; */
    width: 100%;
    /* background: #fff; */
    margin: 0 auto;
    /* just to get an apsect ratio same as the SVG's: */
    /* padding-top: 48%;  */
    /* height: 0;  */
    background-image: url(./utils/Resources/images/PatternHex.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

section {
    margin: 20rem 0 20rem 0;
}

.MuiPopper-root li a{
    width: 100%
}

.blogModal {
    width: 50%;
    padding: 0px;
  }
.blogModal::backdrop {
    background: rgba(0, 0, 0, 0.8);
  }